import Vue from 'vue'

import i18n from '@/i18n'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import '@/styles/variables.scss'

// chenxi 20200809
// 引入 material design 图标库。官网：https://materialdesignicons.com/
// 在代码中使用例如： <v-icon> mdi-home </v-icon>
import '@mdi/font/css/materialdesignicons.css'

// 引入 fontawesome 图标库。官网：https://fontawesome.com/
// 在代码中使用例如： <v-icon> fa-cog </v-icon>
import '@fortawesome/fontawesome-free/css/all.css'

// chenxi 20200807
// Vuetify 支持 light / dark 两个主题。设计师或者程序员可以给每个主题自定义颜色。如果自定义的主题比较多，也可以独立放在一个文件中。
/*
  import {theme_x,theme_y} from '@/themes'
*/


Vue.use(Vuetify,{
  // 根据安装的 icon 库，可以使用 'md' || 'mdi' || 'mdi-svg' || 'fa' || 'fa4' 
  // 经过查阅 Vuetify 官方文档 https://github.com/vuetifyjs/vuetify/tree/master/packages/vuetify/src/services/icons/presets
  // vuetify 默认支持 md, mdi, mdi-svg, fa5, fa4 这 5 种图标库。只要安装了这几种图标库，下面这句代码可以省略。
  iconfont: 'mdi' ||'fa' || 'md'  
})

// chenxi 20200807
//
// Vuetify 缺省支持 light / dark 两种主题，通过 this.$vuetify.theme.dark = false / true 来切换主题。
// 每种主题缺省包括 7 个颜色变量，设计师或者程序员可以自由配置这 7 个颜色变量（如下所示）。也可以再向主题中额外加入自定义变量。 
//
// Vuetity 的所有组件，HTML 标签，都可以很方便的使用这些变量。 
// 例如设计师选择天蓝色(light-blue) 作为 primary, 中灰色(grey) 作为 secondary, 那么:
//
// 对于 Vueitfy 组件：<v-system-bar class="primay" /> , 就会使用 primary 作为 system-bar 的背景色。
// 对于 HTML 标签：<div class="secondary" /> 就会使用 secondary 作为 div 的背景色。
// 对于 HTML 文本：<span class="secondary--text">some text</span> 就会使用 secondary 作为 span 内的文本颜色。
// 
// 另外，在这7种颜色基础上，程序员还可以配合 lighten-{1-5}, darken-{1-5} 来对颜色进行减淡或者加深操作。例如：
//
// 对于 Vuetify 组件：<v-system-bar class="primary lighten-2" /> 就会使用比 primary (天蓝色) 浅2级的淡蓝色作为背景色。
// 对于 HTML 标签：<div class="secondary lighten-2" /> 就会使用比 secondary (中灰色) 浅2级的浅灰色作为背景色。
// 对于 HTML 文本：<span class="secondary--text text--darken-2"> some text </span> 会使用比 secondary (中灰色) 深2级的深灰色作为文本颜色。
//
// 具体的颜色对照表，请查看：https://vuetifyjs.com/en/styles/colors/#colors

import colors from 'vuetify/lib/util/colors'

const theme_light = {
    primary: '#07848D',
    secondary: colors.grey.lighten2,
    accent: colors.deepOrange.base,
    error: colors.red.accent2,
    info: colors.blue.base,
    success: colors.green.base,
    warning: colors.amber.darken2,

    // 当以上标准的颜色变量不能满足需求时，程序员也可以自定义颜色变量。请注意两种主题要保持变量名一致。
    border: colors.grey.lighten4,
    configBoxBg:  colors.grey.lighten5,
    configBg:  colors.grey.lighten2,
    configBorder: colors.grey.lighten3,
    cnofigTitle: colors.grey.lighten1,
    appBarColor: '#FFF',
    pannelConfigBackground: colors.grey.lighten5,
    pannelBackground: colors.grey.lighten5,
    textColor: colors.grey.darken4,
  }

const theme_dark = {
  primary: '#07848D',
  secondary: colors.grey.darken4,
  accent: colors.deepOrange.darken3,
  error: colors.red.accent4,
  info: colors.blue.darken3,
  success: colors.green.darken3,
  warning: colors.amber.darken3,

  // 自定义颜色变量。请注意两种主题要保持变量名一致。
  border: colors.grey.darken4,
  configBoxBg:  colors.grey.darken4,
  configBg:  colors.black,
  configBorder: colors.grey.darken3,
  appBarColor: colors.grey.darken4,
  pannelConfigBackground: colors.grey.darken3,
  pannelBackground: colors.grey.darken1,
  textColor: colors.grey.lighten1,
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: theme_light,
      dark:  theme_dark,
    },
  },
})
