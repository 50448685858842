export default {
  projectInfo: {},
  typeList: [],
  newproject_form: {
    name: "广州电视塔",
    evaluation_system: 1,
    building_type: 7,
    climatic_region: 4,
    area: 12345.67,
    storey: 111,
    completion_year: 2009,
    province: "广东",
    address: "广东省广州市海珠区阅江西路222号",
    description:
      "广州塔位于广州城市新中轴线与珠江景观轴交汇处，与举办第十六届亚运会开闭幕式的海心沙岛和广州市21世纪CBD区珠江新城隔江相望，目前是中国第一、世界第三的旅游观光塔。广州塔建筑总高度600米，其中主塔体高450米，天线桅杆高150米，以中国第一、世界第三的观光塔的地位，向世人展示腾飞广州、挑战自我、面向世界的视野和气魄。广州塔，因其独特设计造型，与珠江交相辉映，成为新中轴线上的亮丽景观。",
    owner: "XXXX公司",
    contact: "webmaster",
    telephone: "020-89338222",
    email: "guangzhouta@cantontower.com",
  }, // 提交新申请时，由企业用户填入的资料

  building_type_arr: [
    { text: "普通居住建筑", value: 1 },
    { text: "高层居住建筑", value: 2 },
    { text: "办公楼", value: 3 },
    { text: "学校", value: 4 },
    { text: "商场", value: 5 },
    { text: "医院", value: 6 },
    { text: "其他", value: 7 },
  ],
  evaluation_system_arr: [{ text: "绿色健康建筑评价标准 2020 V1.0", value: 1 }],
  climatic_region_arr: [
    { text: "严寒", value: 1 },
    { text: "寒冷", value: 2 },
    { text: "夏热冬冷", value: 3 },
    { text: "夏热冬暖", value: 4 },
    { text: "温和", value: 5 },
  ],
  province_arr: [
    "北京",
    "上海",
    "重庆",
    "天津",
    "广东",
    "浙江",
    "江苏",
    "河北",
    "山东",
  ],
};
