<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import { getTencentCosUploadTempKey } from "@/api/projects";

export default {
  name: "App",

  data: () => ({
    //
  }),

  methods: {},

  async mounted() {
    // chenxi 20200924
    // 程序启动时，初始化腾讯云 COS SDK(对象存储服务), 用于从前端直接上传文件至腾讯云 COS. 初始化后得到的实例存入 Vuex, 供其他页面直接调用。
    const COS = require("cos-js-sdk-v5");
    let cos = new COS({
      // 必选参数
      getAuthorization: function(options, callback) {
        // 从我们自己的后台获取临时 SecretId 和 SecretKey. 前端 JavaScript SDK 用临时 SecretId 和 SecretKey 自行计算签名。
        getTencentCosUploadTempKey().then((result) => {
          callback({
            TmpSecretId: result.credentials.tmpSecretId,
            TmpSecretKey: result.credentials.tmpSecretKey, // 临时 SecretId 和 SecretKey. 有效期由我们的后台设定。例如3600秒。
            XCosSecurityToken: result.credentials.sessionToken,
            StartTime: result.startTime, // 时间戳，单位秒，如：1580000000
            ExpiredTime: result.expiredTime, // 时间戳，单位秒，如：1580000900
          });
        });
      },
    });
    this.$store.commit("setCos", cos);
  },
};
</script>

<style scoped></style>
