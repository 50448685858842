export default {
  SETPROJECT_INFO(state, payload) {
    state.projectInfo = payload;
  },
  SETPROJECT_FIELD(state, payload) {
    state.projectInfo[payload.name] = payload.value;
  },
  SETTYPELIST(state, payload) {
    state.typeList = payload;
  },
  SETEXPERTLIST(state, payload) {
    state.expertList = payload;
  },
  SETTESTITEMLIST(state, payload) {
    state.testitemList = payload;
  },
  SETNEWPROJECT_FORM(state, payload) {
    state.newproject_form = payload;
  },
};
