import axios from "axios";
import Vue from "vue";
import router from "@/router";

const instance = axios.create({
    // chenxi 200200910
    // 这里设置 baseURL 开发时跟后端连接，会有跨域的问题。比较合适的方法是在 vue.config.js 中通过 proxy 来转发请求。
    // 那样是通过 webpack-dev-server 转发，没有跨域问题。
    // baseURL: 'https://cqc.mtec.cc',
    timeout: 8000,
});
// 添加请求拦截器
instance.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        const token = "Bearer " + window.localStorage.getItem("token");
        if (token) {
            config.headers.authorization = token;
        }
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
instance.interceptors.response.use(
    function (response) {
        // 对响应数据做点什么
        return response.data;
    },
    function (error) {
        // 对响应错误做点什么
        /*
         * @Description: 判断是否是'invalid', '500', '501', '502'
         * @Author: 张宇童
         * @Date: 2020/9/6 15:50
         */
        let error_msg = "";

        if (
            error.response.data.code === 'token_not_valid' &&
            error.response.status === 401 &&
            error.response.statusText === 'Unauthorized'
        ) {
            localStorage.removeItem('token')
            router.push('/admin/login')
            Vue.prototype.$snackbar({
                content: '验证过期，请重新登录',
                color: 'error',
                showClose: true,
            })
        }

        switch (error.response.status) {
            case 400:
                for (let i in error.response.data) {
                    error.response.data[i].forEach((x) => (error_msg += x + "<br />"));
                }
                Vue.prototype.$message.error(error_msg);
                break;
            case 404:
                Vue.prototype.$snackbar({
                    content: '页面不存在',
                    color: 'error',
                    showClose: true,
                })
                break;
            case 500:
            case 501:
            case 502:
            case 503:
            case 504:
                Vue.prototype.$snackbar({
                    content: '服务器错误',
                    color: 'error',
                    showClose: true,
                })
                break;
            default:
                Vue.prototype.$snackbar({
                    content: '系统繁忙',
                    color: 'error',
                    showClose: true,
                })
        }
        return Promise.reject(error);
    }
);

export default instance;
