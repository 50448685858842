import request from "@/request";
/*
 * @Description: 获取项目列表
 * @Author: 张宇童
 * @Date: 2020/9/14 12:18
 */
export const getProjectList = params =>
  request.get("/api/projects", { params });

export const getProjectLogs = params =>
  request.get("/api/projectlogs", { params });
/*
 * @Description: 获取项目单个信息
 * @Author: 张宇童
 * @Date: 2020/9/14 12:18
 */
export const getProjectInfo = id => request.get(`/api/projects/${id}`);

export const addProject = data => request.post(`/api/projects/`, data);
export const updateProject = data =>
  request.patch(`/api/projects/${data.id}/`, data);

export const getTencentCosUploadTempKey = () =>
  request.get(`/api/system/tencent/cosuploadtempkey/`);

export const getMediaList = params => request.get(`/api/media/`, { params });
export const addMedia = data => request.post(`/api/media/`, data);
export const removeMedia = id => request.delete(`/api/media/${id}`);
export const updateMedia = data =>
  request.patch(`/api/media/${data.id}/`, data);
