import Vue from 'vue'
import VueI18n from 'vue-i18n'

import vuetify_en from 'vuetify/lib/locale/en'
import vuetify_cn from 'vuetify/lib/locale/zh-Hans'

import iview_en from 'view-design/dist/locale/en-US';
import iview_cn from 'view-design/dist/locale/zh-CN';

Vue.use(VueI18n)


const messages = {
  en: {
    ...require('@/locales/en.json'),
    $vuetify: vuetify_en,
    $iview: iview_en,
  },
  cn: {
    ...require('@/locales/cn.json'),
    $vuetify: vuetify_cn,
    $iview: iview_cn,
  },
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages,
})


