import Vuex from "vuex";
import Vue from "vue";
import projectManage from "@/pages/admin/projects/store";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    cos: null,
    menu: [],
    user: null,
  },
  mutations: {
    setCos(state, payload) {
      state.cos = payload;
    },
    setMenu(state, payload) {
      state.menu = payload;
      window.localStorage.setItem("menu", JSON.stringify(payload));
      console.log("menu set...", state.menu);
    },
    setUser(state, payload) {
      state.user = payload;
      window.localStorage.setItem("user", JSON.stringify(payload));
      console.log("user set...", state.user);
    },
  },
  actions: {},
  modules: {
    projectManage,
  },
});

export default store;
